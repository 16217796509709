"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.validEscalationTypes = exports.AllTemplatesRequestType = exports.AlertSeverity = exports.CustomAlertName = exports.alertNameToSeverityFieldMap = exports.SystemAlertName = void 0;
var SystemAlertName;
(function (SystemAlertName) {
    SystemAlertName["CONSENT_CAPTURED"] = "Patient Consented";
    SystemAlertName["CONSENT_INVALID"] = "Consent Response Invalid";
    SystemAlertName["STOPPED_TEXTS"] = "Stopped Texts";
    SystemAlertName["STARTED_TEXTS"] = "Started Texts";
    SystemAlertName["HELP_WITH_TEXTS"] = "Help with Texts";
    SystemAlertName["INCOMING_MESSAGE"] = "Incoming Message";
    SystemAlertName["INCOMING_MESSAGE_FELIX_DISABLED"] = "Incoming Message When Felix Disabled";
    SystemAlertName["UNANSWERED_QUESTION"] = "Unanswered Question";
    SystemAlertName["INCOMING_QUESTION"] = "Incoming Question";
    SystemAlertName["MESSAGE_REQUEST"] = "Message Request";
    SystemAlertName["LANDLINE_PHONE_NUMBER"] = "Landline Phone Number";
    SystemAlertName["UNDELIVERED_QUESTION"] = "Message Not Delivered";
    SystemAlertName["ARCHIVED_MESSAGE"] = "Archived Patient Messaged";
    SystemAlertName["UNEXPECTED_SURVEY_RESPONSE"] = "Unexpected Survey Response";
    SystemAlertName["PATIENT_IMAGE_SENT"] = "Patient Image Sent";
    SystemAlertName["DOB_VERIFICATION_FAILED"] = "Date of Birth Verification Failed";
})(SystemAlertName = exports.SystemAlertName || (exports.SystemAlertName = {}));
// used in alerts config v2 to add severity in the record for every alert config
exports.alertNameToSeverityFieldMap = {
    "Patient Consented": "consent.consentedConcernSeverity",
    "Consent Response Invalid": "consent.consentResponseInvalidConcernSeverity",
    "Stopped Texts": "textConcerns.stopConcernSeverity",
    "Started Texts": "textConcerns.startConcernSeverity",
    "Help with Texts": "textConcerns.helpConcernSeverity",
    "Incoming Message": "allMessagesConcern.severity",
    "Incoming Message When Felix Disabled": "allMessagesFelixDisabledConcern.severity",
    "Unanswered Question": "felix.alertSeverity",
    "Incoming Question": "felix.alertSeverity",
    "Message Request": "messageRequestAlertSeverity",
    "Landline Phone Number": "landlineNumberSeverity",
    "Message Not Delivered": "undeliveredNotificationAlertSeverity",
    "Archived Patient Messaged": "archivedMessageAlertSeverity",
    "Unexpected Survey Response": "unexpectedSurveyResponseConcernSeverity",
    "Patient Image Sent": "patientImageAlertSeverity",
    "Date of Birth Verification Failed": "dobVerificationFailedConcernSeverity",
};
var CustomAlertName;
(function (CustomAlertName) {
    CustomAlertName["USER_CREATED_TASK"] = "User-created task";
})(CustomAlertName = exports.CustomAlertName || (exports.CustomAlertName = {}));
var AlertSeverity;
(function (AlertSeverity) {
    AlertSeverity["CRITICAL"] = "Follow-Up";
    AlertSeverity["ADVISORY"] = "Concerning";
    AlertSeverity["INFO"] = "No Concerns";
})(AlertSeverity = exports.AlertSeverity || (exports.AlertSeverity = {}));
var AllTemplatesRequestType;
(function (AllTemplatesRequestType) {
    AllTemplatesRequestType["DYNAMIC"] = "dynamic";
})(AllTemplatesRequestType = exports.AllTemplatesRequestType || (exports.AllTemplatesRequestType = {}));
var validEscalationTypes;
(function (validEscalationTypes) {
    validEscalationTypes["ESCALATION_INSTANCE"] = "Escalation Instance";
    validEscalationTypes["SYSTEM_ESCALATION"] = "System Escalation";
    validEscalationTypes["LEGACY_ESCALATION"] = "Legacy Escalation";
})(validEscalationTypes = exports.validEscalationTypes || (exports.validEscalationTypes = {}));
